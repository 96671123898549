.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.hdata{
  height: 3rem;
  color: green;
}
.scan_btn{
  width: 2rem;
  height: 2rem;
  background-color: rgb(116, 30, 214);
  color: white;
  line-height: 3rem;
  margin-left: .3rem;
  line-height: 2rem;
  font-size: 12px;
  border-radius: .4rem;
}
.input_line{
  height: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: .5rem 1rem;
  
}
.input_line span{
  width: 5rem;
}
.input_line select,input{
  width: 12rem;
  height: 2rem;
}

.input_line input{
  width: 11.5rem;
  height: 1.8rem;
}
.red{
  color: red;
}
.save_btn{
  width: 15rem;
  background-color: green;
  height: 3rem;
  margin: 2rem auto;
  justify-content: center;
  align-items: center;
  display: flex;
  color: white;
  border-radius: .6rem;
}
.headtitle{
  color: green;
  font-size: 18px;
  margin: 1rem auto;
  text-align: center;
  width: 80%;
  height: 2rem;
  display: block;
}
